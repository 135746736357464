.realm-object-viewer
{
    background-color: var(--background-medium) !important;
    margin: 8px;
    border-radius: 8px !important;
}

.ant-layout-header
{
    background-color: var(--background-dark);
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.parent-container {
  display: flex;
  flex-direction: column; /* stack children vertically */
  align-items: stretch; /* ensures child elements fill the width */
  height: auto; /* adjusts height to fit children */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full viewport height */
    text-align: center;
  }
  
  .ant-layout-sider{
    background-color: var(--background-dark) !important;
    padding: 0px;
    margin: 0px;
    max-width: 400px !important;
    width: 400px !important;
  }
 
  .ant-layout-sider-trigger{
    background-color: var(--background-light) !important;
    color: var(--body-text-dark) !important;
  }