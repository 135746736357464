.realm-object-viewer .content-wrapper {
    margin-top: 16px;
  }
  
  @media (max-width: 768px) {
    .realm-object-viewer .content-wrapper {
      flex-direction: column;
    }
  
    .realm-object-viewer .content-wrapper > div {
      width: 100%;
    }
  }
  