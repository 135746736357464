.iconified-title {
  display: flex; /* Ensures flexbox layout */
  align-items: center; /* Aligns items vertically to the center */
}


.iconified-title-text  {
  margin: 0 !important;
  
}



