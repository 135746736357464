/* SiteHeader.css */
.site-header-row {
  padding: 8px 0;
  background-color: var(--background-dark);
  min-width: 100%;
  min-height: 100% !important;
}

.ant-space-item
{
  display: flex;
  margin: 0px !important;
  padding: 0px !important;
}

.logo-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px; /* Adds 16px padding to the left */
  width: auto;
}

.input-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px; /* Adds 16px padding to the right */
  padding-bottom: 0px;
  padding-top: 0px;
  margin: 0px;
  width: auto;
  gap: 0px; /* Adds space between the inputs */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .site-header-row {
    flex-direction: column;
    align-items: center;
  }

  .logo-col, .input-col {
    width: 100%;
    justify-content: center;
    padding: 8px 0; /* Adds padding to the top and bottom */
  }

  .input-col {
    gap: 8px; /* Reduces space between inputs on smaller screens */
  }
}

@media (max-width: 576px) {
  .site-header-row {
    padding: 8px 0;
  }

  .logo-col, .input-col {
    width: 100%;
    justify-content: center;
    padding: 8px 0; /* Adds padding to the top and bottom */
  }

  .input-col {
    gap: 8px; /* Reduces space between inputs on smaller screens */
  }
}

.test-style{
  color: red;
}