.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-dark);
  }
  
  .centered-card {
    width: 100%;
    max-width: 600px;
    padding: 40px;
    text-align: center;
    box-shadow: 4px 4px 16px 0px rgba(var(--background-medium-light-rgb), 0.5) !important;
    background-color: var(--background-medium-dark);
    border-radius: 10px;
    border: 2px solid rgba(var(--background-medium-light-rgb), 0.3);
  }
  
  .signup-title {
    font-weight: bold;
    margin-bottom: 24px;
    color: var(--body-text-light) !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .signup-text {
    size: 16px;
    font-size: var(--body-text-size-large);
    font-family: var(--main-font);
    font-weight: bold;
    padding: 10px 20px;
    background-color: var(--background-light) !important;
    color: var(--body-text-dark) !important;
    border-color: var(--background-accent-light) !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .signup-btn {
    size: 16px;
    font-size: var(--body-text-size-large);
    font-family: var(--main-font);
    font-weight: bold;
    padding: 10px 20px;
    background-color: var(--background-light) !important;
    color: var(--body-text-dark) !important;
    border-color: var(--background-accent-light) !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .signup-btn:hover,
  .signup-btn:focus {
    background-color: var(--background-very-light-2) !important;
    color: var(--body-text-dark) !important;
    border-color: var(--background-accent-medium) !important;
  }
  
  .signup-btn:disabled {
    background-color: var(--button-primary-background-disabled) !important;
    border-color: var(--button-primary-border-color-disabled) !important;
    color: var(--button-primary-color-disabled) !important;
    cursor: not-allowed;
  }
  

  