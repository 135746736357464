/* Overall Tree Container */
.realm-object-tree {
  display: flex;
  flex-direction: column;
  width:100%; /* Adjust width as needed */
  padding: 16px !important; /* Padding top and bottom */
  margin: 8px !important; /* Add margin */
  background-color: var(--background-dark) !important;
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Ant Tree General Styles */
.ant-tree {
  background-color: var(--background-medium-dark); /* Tree background */
  color: var(--title-text-light);
  padding: 8px 4px;
}

.ant-tree-treenode {
  font-size: var(--body-text-size-medium);
  width: 100% !important;
  display: flex;
  color: var(--title-text-light);
  max-width: 100% !important;
}

.ant-tree-treenode-unselected .ant-tree-node-content-wrapper {
  background-color: var(--background-medium-dark); /* Node background */
  border-left: 4px solid var(--background-medium); /* Left border */
}

.ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  background-color: var(--background-dark) !important;
  margin-left: -4px; /* Align content */
  color: var(--body-text-extra-light) !important; /* Selected text color */
  border-left: 4px solid var(--background-light); /* Left border */
  padding-left: 4px;
  width: calc(100% - 4px); /* Ensure full width minus border */
}

/* Hover effect for nodes */
.ant-tree-treenode:hover .ant-tree-node-content-wrapper {
  border-left: 4px solid var(--background-accent-medium); /* Hover border */
}

/* Draggable Icon Style (Drag Holder Icon) */
.realm-object-tree .ant-tree-draggable-icon {
  color: var(--body-text-light) !important;
  opacity: 0.75 !important;
  margin-right: 8px; /* Space between icon and content */
  order: -1; /* Ensure icon is on the left */
  float: left; /* Align icon to the left */
}

/* Tree Node Expand/Collapse Icon */
.realm-object-tree .ant-tree-switcher {
  font-size: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.realm-object-tree .ant-tree-switcher svg {
  width: 20px;
  height: 20px; /* Adjust size of SVG icons */
}

/* Tree Node Content */
.ant-tree .ant-tree-node-content-wrapper {
  display: flex;
  align-items: center;
  width: 85%;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  margin-right: 10px; /* Space between icon and text */
}

/* Iconified Text (Tree Titles) */
.text-tree {
  color: var(--body-text-light); /* General text color */
  display: flex;
  width: 100%;
}

/* Realm Object Title Style */
.realm-object-title {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between text and kebab */
  background-color: var(--background-dark); /* Node background */
  width: 100%; /* Full width */
  padding-right: 10px; /* Space between kebab and edge */
  box-sizing: border-box; /* Include padding in width calculation */
  flex-grow: 1; /* Allow title to take remaining space */
}

/* Title Content (Iconified Text Section) */
.title-content {
  flex-grow: 1; /* Ensure title text takes up remaining space */
}

/* Kebab Icon Style */
.vertical-kebab-icon {
  font-size: 18px; /* Adjust size */
  cursor: pointer;
  padding-left: 8px; /* Add space between title and kebab */
}

/* Customizations for the Dragging Holder Icon */
.ant-tree-draggable-icon .holder {
  color: var(--body-text-light) !important; /* Draggable icon color */
}

.ant-tree-title {
  display: flex;
  align-items: center;
  width: 100% !important;
  
}
