.campaign-panel-main {
    margin-right: 8px !important; 
    padding: 4px !important;
    background: var(--background-dark);
    border-radius: 8px;
    border-width: 0px !important;
  }

  .ant-card-head,
  .ant-card-head::after {
    content: none !important;
    border: 0px !important;
  }

  .top-card.ant-card {
    background-color: var(--complementary-background-medium);
    padding-top: 8px !important;
    margin-top: 0px !important;
    border-color: var(--background-medium) !important;
  }

  .bottom-card.ant-card {
    background-color: var(--complementary-background-medium);
    padding-top: 8px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-color: var(--background-medium) !important;
  }

  .selected-campaign {
    border-left: 4px solid var(--title-text-dark); /* Add a blue border to indicate selection */
    border-radius: 8px;
    border-bottom: 1px solid var(--title-text-dark) !important;
    cursor: pointer; /* Change the cursor to a pointer when hovering over the campaign */
  }
  
  .selected-campaign .text-hightlight {
    font-weight: bold; /* Make the text bold for the selected campaign */
    color: var(--body-text-option-light); /* Use the theme's primary color */
    padding-left: 12px;
  }
  
  .not-selected-campaign{
    border-left: 4px solid var(--complementary-background-medium); /* Add a blue border to indicate selection */
    border-radius: 8px;
    border-bottom: 1px solid var(--complementary-background-medium) !important;
    cursor: pointer; /* Change the cursor to a pointer when hovering over the campaign */
  }

  .not-selected-campaign .text-hightlight {
    color: var(--body-text-option-light); /* Use the theme's primary color */
    padding-left: 16px;
    
  }

  .campaign-add-input {
    width: 175px;
  }