/* TemplatePanel.css */
:root
{
  --standard-padding: 0px;
  --standard-margin: 0px;
}
.fab-template-panel {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: var(--standard-margin) !important; /* Corrected margin */
  background: var(--background-dark);
}

.fab-template-panel-item {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: var(--background-light); /* Light background */
  margin-bottom:2px; /* Reduced gap between items */
  border-radius: 4px;
  padding: 0px !important; /* Reduced padding */
  width: 100%; /* Ensure items fill the container from left to right */
}

.template-drawer{
  padding: var(--standard-padding) !important;
  margin: var(--standard-margin) !important;
  z-index: 600;
}

.left-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: var(--standard-margin) !important;
}

@media only screen and (min-width: 768px) {
  .left-panel {
    margin-left: var(--standard-margin) !important;
  }
}

@media only screen and (min-width: 992px) {
  .left-panel {
    margin-left: var(--standard-margin) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .left-panel {
    margin-left: var(--standard-margin) !important;
  }
}
