/* css/InputField.css */


.input-margin-bottom {
  margin-bottom: 8px; /* Add margin at the bottom to create space between inputs */
}

.ant-btn {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-btn.right{
  border-radius: 0px 8px 8px 0px;
}


.ant-btn.right:hover,
.ant-btn:hover {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-btn.left {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.ant-btn.left:hover {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-input.custom-title-input {
  width: 100%;
  border-radius: 8px !important;
  /* Background and text color inside the input field */
  background: #5B7882;
  color: #BFE5D3;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline on focus */
}

.user-identity-credential-input {
  width: 100%;
}
