:root {
  --main-font: 'Poppins', sans-serif;
  
  /* Body Text Colors */
  --body-text-light: #BFE5D3; 
  --body-text-light-rgb: 191, 229, 211;

  --body-text-medium: #7fa292;
  --body-text-medium-rgb: 127, 162, 146;

  --body-text-dark: #1D1B20;
  --body-text-dark-rgb: 29, 27, 32;

  --body-text-accent: #F9FDE7;
  --body-text-accent-rgb: 249, 253, 231;

  --body-text-link: #3F535A;
  --body-text-link-rgb: 63, 83, 90;

  --body-text-extra-light: #d8eee4; 
  --body-text-extra-light-rgb: 216, 238, 228;

  /* Title Text Colors */
  --title-text-light: #FA952C;
  --title-text-light-rgb: 250, 149, 44;

  --title-text-glowmode: #FDB662;
  --title-text-glowmode-rgb: 253, 182, 98;

  --title-text-extralight: #D3AC5C; 
  --title-text-extralight-rgb: 211, 172, 92;

  --title-text-dark: #6d4113;
  --title-text-dark-rgb: 109, 65, 19;

  /* Background Colors */
  --background-dark: #142022;
  --background-dark-rgb: 20, 32, 34;

  --background-medium-dark: #1D2E2C;
  --background-medium-dark-rgb: 29, 46, 44;

  --background-medium: #273E3B;
  --background-medium-rgb: 39, 62, 59;

  --background-medium-light: #5B7882; 
  --background-medium-light-rgb: 91, 120, 130;

  --background-light: #BFE5D3;
  --background-light-rgb: 191, 229, 211;

  --background-very-light-2: #d0e7dc;
  --background-very-light-2-rgb: 208, 231, 220;

  --background-very-light: #c9e0d5;
  --background-very-light-rgb: 201, 224, 213;

  /* Background Accent Colors */
  --background-accent-light: #D3AC5C;
  --background-accent-light-rgb: 211, 172, 92;

  --background-accent-medium: #FA952C;
  --background-accent-medium-rgb: 250, 149, 44;

  --background-accent-dark: #6d4113;
  --background-accent-dark-rgb: 109, 65, 19;

  /* Border Colors */
  --border-color-dark: #1D2E2C;
  --border-color-dark-rgb: 29, 46, 44;

  --border-color-medium: #273E3B;
  --border-color-medium-rgb: 39, 62, 59;

  --border-color-light: #5B7882;
  --border-color-light-rgb: 91, 120, 130;

  /* Gradient Backgrounds */
  --gradient-background-1: var(--background-dark);
  --gradient-background-2: var(--background-light);
  --gradient-background-before: #1D2E2C;

  /* Button Primary */
  --button-primary-background: var(--background-accent-dark);
  --button-primary-color: var(--title-text-extralight);
  --button-primary-border: 1px;
  --button-primary-border-color: var(--title-text-extralight);
  --button-primary-hover-color: var(--title-text-extralight);
  --button-primary-background-disabled: #6a5742;
  --button-primary-background-disabled-rgb: 106, 87, 66;

  --button-primary-color-disabled: #84817d;
  --button-primary-color-disabled-rgb: 132, 129, 125;

  --button-primary-border-color-disabled: #84817d;
  --button-primary-border-color-disabled-rgb: 132, 129, 125;

  /* Button Secondary */
  --button-secondary-background: var(--background-dark);
  --button-secondary-color: var(--body-text-light);
  --button-secondary-border: 1px;
  --button-secondary-border-color: var(--border-color-medium-light);
  --button-secondary-background-disabled: var(--background-dark);
  --button-secondary-color-disabled: #84817d;
  --button-secondary-color-disabled-rgb: 132, 129, 125;

  --button-secondary-border-color-disabled: #84817d;
  --button-secondary-border-color-disabled-rgb: 132, 129, 125;

  /* Text Disabled */
  --text-disabled-color: #84817d;
  --text-disabled-color-rgb: 132, 129, 125;

  /* Header */
  --header-height: 45px;
}


/* General Styles */
body {
  background-color: var(--background-dark);
}

.App {
  text-align: center;
  padding: 10px;
  background-color: var(--background-dark);
}

.fullscreen-background {
  background-color: var(--background-dark);
  min-height: 100vh;
}

.main-background {
  background-color: var(--background-dark) !important;
  padding-top: var(--header-height);
}

.panel-background-medium{
  background-color: var(--background-medium) !important;
}

.panel-background-light{
  background-color: var(--background-light) !important;
}

.panel-border-light{
  border-color: var(--border-color-light);
  border-radius: 8px;
  border: 2px;
}

.panel-small
{
  padding: 0px !important;
}

/* Typography */
.custom-title h1.ant-typography,
.custom-title h2.ant-typography,
.custom-title h3.ant-typography,
.custom-title h4.ant-typography,
.custom-title h5.ant-typography {
  font-family: var(--main-font);
}

.custom-title-2 h1.ant-typography,
.custom-title-2 h2.ant-typography,
.custom-title-2 h3.ant-typography,
.custom-title-2 h4.ant-typography,
.custom-title-2 h5.ant-typography {
  color: var(--body-text-dark) !important;
  font-family: var(--main-font);
}

.custom-title,
.custom-title h1.ant-typography,
.custom-title h2.ant-typography,
.custom-title h3.ant-typography,
.custom-title h4.ant-typography,
.custom-title h5.ant-typography {
  color: var(--title-text-light) !important;
}

.markdown-text-primary,
.ant-typography.text-primary {
  color: var(--body-text-light) !important;
  font-family: var(--main-font);
}

.ant-typography.text-primary.dark-mode,
.text-dark-mode {
  color: var(--body-text-dark);
  font-family: var(--main-font); 
}

.txt-bold{
  font-style: bold !important;
}

.ant-typography.text-primary {
  color: var(--body-text-dark);
  font-family: var(--main-font);
}

.ant-typography.text-url{
  color: var(--body-text-light);
  font-family: var(--main-font);
  font-size: var(--body-text-size-small);
  background-color: var(--background-medium-light);
  border-radius: 4px;
  padding: 6px;
  border-color: var(--background-medium);
}

.text-secondary {
  color: var(--body-text-light);
  font-size: var(--body-text-size-medium);
}

.ant-typography.text-secondary {
  color: var(--body-text-light);
  font-family: var(--main-font);
}

.ant-typography.text-highlight, 
.ant-typography.text-success {
  color: var(--body-text-accent);
  font-family: var(--main-font);
}

.ant-typography.text-warning {
  color: var(--background-accent-medium);
  font-family: var(--main-font);
}

.ant-typography.text-danger {
  color: var(--title-text-dark);
  font-family: var (--main-font);
}

.ant-typography.text-disabled {
  color: var(--text-disabled-color);
  font-family: var (--main-font);
}

.ant-typography.text-large {
  font-size: var(--body-text-size-large) !important;
}

.ant-typography.text-medium {
  font-size: var(--body-text-size-medium);
}

.ant-typography.text-small {
  font-size: var(--body-text-size-small);
}

.ant-typography.text-underline {
  text-decoration: underline;
}

.ant-typography.text-delete {
  text-decoration: line-through;
}

.fab-body-text-orange {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: var(--body-text-size-small);
  line-height: 20px;
  color: var(--title-text-light) !important;
}

.text-orange {
  font-family: var(--main-font);
  color: var(--title-text-light) !important;
}

.ant-list-empty-text {
  color: var(--body-text-dark);
}

/* Header Styles */
.site-header {
  min-height: var(--header-height) !important;
  min-width: 100%;
  width: 100% !important;
  padding: 0px !important;
}

.fab-header {
  font-family: var(--main-font) !important;
  color: var(--title-text-light) !important; /* Ensure this variable is defined */
}

.tab-container
{
  position: absolute top right; /* Relative positioning for the container */
  min-height: 500px;
}

/* Logo */
.logo {
  max-height: 65px; /* Ensures the logo does not exceed a certain height */
  max-width: 65px; /* Ensures the logo scales proportionally */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Maintains aspect ratio */
  display: block; /* Centers the logo if it's in a container with text-align: center */
  margin-right: 16px; /* Adds some spacing to the right of the logo */
}

.ant-col .custom-align-top
{
  align-items: top !important;
  align-self: top !important;
  align-content: top !important;
}

/* Centering Elements */
.center-element,
.center-elements,
.center-title,
.fab-title-center,
.fab-title-middle,
.fab-center-content {
  align-items: center;
  align-content: center;
  text-align: center;
  align-self: center;
  justify-content: center;
}

.fab-align-right {
  align-items: right;
  align-self: right;
  text-align: right;
}

/* Panels and Cards */
.fab-panel-card,
.fab-panel-main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 8px auto; /* Corrected margin */
  background: var(--background-dark);
  border-radius: 8px;
}

.fab-fill-parent {
  width: 100%;
  height: 100%;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


.fab-card {
  width: 100%;
  height: 100%;
  gap: 10px;
  display: flex;
  margin-left: 8px;
}

.fab-card-header-icon {
  margin-right: 5px !important; /* Adjust the spacing between the icon and the title as needed */
  align-self: center !important;
}

.fab-standard-pad {
  padding: 16px;
  min-height: 400px;
}

.fab-narrow-pad {
  padding: 8px;
}

.ant-drawer-close
{
  color: var(--title-text-light) !important;
}


.ant-layout {
  background-color: var(--background-dark);
}

.ant-layout-content.layout-content {
  height: 80vh;
}

/* Set the layout container to fill the entire viewport */
.layout-container {
  display: flex;
  flex-direction: column; /* Header on top, Sider and Content below */
  height: flex; /* Full height of the viewport */
  min-height: 40vh;
  background-color: var(--background-dark);
}

/* The layout body (Sider + Content) will fill the remaining space below the header */
.layout-body {
  display: flex;
  flex-grow: 1; /* Take the remaining height below the header */
}

/* The Sider should take a fixed width and stretch to the full height */
.left-sider {
  background-color: var(--background-dark) !important;
  color: var(--body-text-light) !important;
  height: 100%; /* Stretch to fill the available height */
}

/* The Content should take the remaining width and fill the height */
.content-panel {
  padding: 0px;
  margin: 0px;
  background-color: var(--background-medium-dark) !important; 
  flex-grow: 1; /* Fill the remaining width after the Sider */
  min-height: 40vh;

}


/* Buttons */
.ant-btn.primary {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: var(--button-primary-border-color);
  font-size: var(--body-text-size-medium);
  font-family: var(--main-font);
}

.primary-small
{
  margin: 0px !important;
}

.ant-btn.copy-button{
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  border-color: var(--button-primary-border-color);
  margin: 2px !important; 
  margin-top: 4px !important;
  height: 24px;
  width: 24px;
}

.ant-btn.primary:hover,
.ant-btn:focus {
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border-color: var(--button-primary-border-color) !important;
}

.ant-btn.primary:disabled {
  background-color: var(--button-primary-background-disabled) !important;
  border-color: var(--button-primary-border-color-disabled) !important;
  color: var(--button-primary-color-disabled);
  cursor: not-allowed;
}

.ant-btn.primary:disabled:hover,
.ant-btn.primary[disabled]:hover {
  background-color: var(--button-primary-background-disabled) !important;
  border-color: var(--button-primary-border-color-disabled) !important;
  color: var(--button-primary-color-disabled) !important;
  cursor: not-allowed;
}

.ant-btn.secondary-btn {
  size: 16px;
  font-size: var(--body-text-size-medium);
  font-family: var(--main-font);
  background-color: var(--button-secondary-background) !important;
  color: var(--button-secondary-color) !important;
  border-color: var(--button-secondary-border-color) !important;
}

.ant-btn.secondary-btn:hover,
.ant-btn-secondary-btn:focus {
  background-color: var(--background-light) !important;
  color: var(--body-text-dark) !important;
  border-color: var(--button-secondary-border-color) !important;
}

.ant-btn.secondary-btn:disabled {
  background-color: var(--button-secondary-background-disabled) !important;
  border-color: var(--button-secondary-border-color-disabled) !important;
  color: var(--button-secondary-color-disabled) !important;
  cursor: not-allowed;
}

.ant-btn.secondary-btn:disabled:hover {
  background-color: var(--button-secondary-background-disabled) !important;
  border-color: var(--button-secondary-border-color-disabled) !important;
  color: var(--button-secondary-color-disabled) !important;
}

.ant-btn-circle {
  background-color: var(--background-accent-dark);
  color: var (--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ant-btn-circle:hover {
  background-color: var(--title-text-light) !important;
  color: var (--background-accent-dark) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-btn-circle:disabled {
  background-color: var(--button-primary-background-disabled) !important;
  color: var (--button-primary-color-disabled) !important;
  border: 1px solid var(--button-primary-border-color-disabled) !important;
  box-shadow: none !important;
}

.ant-btn-circle:disabled:hover {
  background-color: var (--button-primary-background-disabled) !important;
  color: var (--button-primary-color-disabled) !important;
  border: 1px solid var (--button-primary-border-color-disabled) !important;
  box-shadow: none !important;
}

.gradient-button-border {
  --gradient-border-width: 1px;
  border-radius: 8px;
  background: linear-gradient(to bottom, #28A155, #CBF6F1); /* Set the background gradient */
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
}

.gradient-button-border:hover {
  --gradient-border-width: 1px;
  border-radius: 8px;
  background: linear-gradient(to top, #28A155, #CBF6F1); /* Set the background gradient */
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
}

.gradient-button-border::before {
  content: '';
  display: block;
  height: calc(100% - calc(var(--gradient-border-width)*2));
  width: calc(100% - calc(var(--gradient-border-width)*2));
  border-radius: 8px;
  background: var(--button-primary-background);
  position: absolute;
  top: var(--gradient-border-width);
  left: var(--gradient-border-width);
  z-index: -1;
}

/* Forms and Inputs */
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  width: 100%;
  padding: 8px;
  font-size: var(--body-text-size-medium);
  border-radius: 8px;
  background: var(--background-medium-light) !important;
  color: var(--body-text-light);
  border: none;
  outline: none;
}

.ant-form-item-control-input.large-size
{
  font-size: var(--body-text-size-large) !important;
}

.custom-form-item .ant-form-item-label > label
{
  color: var(--title-text-light); /* Replace with your desired color */
  font-size: var(--body-text-size-large); /* Adjust the size if needed */

}

.large-size
{
  font-size: var(--body-text-size-large) !important;
}


.medium-size
{
  font-size: var(--body-text-size-medium) !important;
}

.ant-input.right {
  /* Add rounded corners */
  border-radius: 0px 8px 8px 0px;
}

.ant-input.left {
  /* Add rounded corners */
  border-radius: 8px 0px 0px 8px;
}

.ant-select-dropdown
{
  background-color: var(--background-light) !important;
  color: var(--body-text-dark) !important;
  font-size: var(--main-font);
}

.ant-select-item-option-selected
{
  background-color: var(--background-very-light) !important;
  color: var(--body-text-dark) !important;
}

.ant-select-item-option-active
{
  background-color: var(--background-medium) !important;
  color: var(--body-text-extra-light) !important;
  border-radius: 8px !important;
}


.ant-select-outlined,
.ant-select-selector {
  /* Add rounded corners */
  border-radius: 8px !important;
  height: 100% !important;
  background-color: var(--background-light) !important;
  color: var(--body-text-dark) !important;
  font-size: var(--main-font);
}


.ant-input::placeholder,
.ant-input-password::placeholder {
  color: var(--body-text-medium);
  text-align: center;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-password:focus,
.ant-input-password:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
  background-color: var(--background-light) !important;
  color: var(--title-text-dark) !important;
}

.ant-input-password-icon {
  color: var(--body-text-light);
}

.ant-input-affix-wrapper .ant-input-password-icon:hover {
  color: var(--title-text-light);
}

.tree-control,
.form-control {
  margin: 10px 0;
}

/* Avatars */
.ant-avatar {
  font-family: var(--main-font);
  display: inline-block;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: var(--background-medium-dark);
  color: var(--body-text-light);
  text-align: center;
  line-height: 40px; /* Should match the height to center the text vertically */
  font-size: var(--body-text-size-medium);
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 2px solid var(--background-accent-dark);
}

.ant-avatar:hover {
  background-color: var(--background-medium);
  color: var(--title-text-light);
}

.ant-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ant-avatar-icon {
  font-size: 24px; /* Adjust icon size as needed */
  vertical-align: middle;
}

/* Drawer */
.ant-drawer-content {
  background-color: var(--background-dark);
  color: var(--body-text-light);
}

.ant-drawer-header {
  background-color: var(--background-medium-dark);
  color: var(--title-text-light);
  border-bottom: 1px solid var(--border-color-medium);
}

.ant-drawer-title {
  font-family: var(--main-font);
  font-size: var(--body-text-size-large);
}

.ant-drawer-close {
  color: var(--title-text-light);
}

.ant-drawer-body {
  background-color: var(--background-dark);
  color: var(--body-text-light);
}

/* Custom Drawer Close Button */
.custom-drawer .ant-drawer-close {
  color: var(--title-text-light);
}

.custom-drawer .ant-drawer-close:hover,
.custom-drawer .ant-drawer-close:focus {
  color: var(--title-text-glowmode);
}

/* Miscellaneous */
.primary-icon {
  color: var(--background-accent-light);
}

.custom-row.fab-panel {
  min-block-size: 400px;
}

.fab-label-container {
  display: flex;
  align-items: center;
}

.fab-info-icon {
  font-size: 16px;
  color: var(--body-text-accent);
  margin-left: 8px;
}

.fab-input {
  width: 100%;
}

/* Avatar */
.ant-avatar {
  font-family: var(--main-font);
  display: inline-block;
  width: 40px; /* Adjust size as needed */
  height: 40px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: var(--background-medium-dark);
  color: var(--body-text-light);
  text-align: center;
  line-height: 40px; /* Should match the height to center the text vertically */
  font-size: var(--body-text-size-medium);
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 2px solid var(--background-accent-dark);
}

.ant-avatar:hover {
  background-color: var(--background-medium);
  color: var(--title-text-light);
}

.ant-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.ant-avatar-icon {
  font-size: 24px; /* Adjust icon size as needed */
  vertical-align: middle;
}


.transparent-background {
  background-color: var(--background-dark) !important;
  border-radius: 8px !important;
}

/* Ant Layout Sider */
.ant-layout-sider-children {
  background-color: var(--background-dark) !important;
}

/* Ant Space */
.ant-space.frame {
  padding: 0px;
  border: 6px solid var(--background-accent-medium) !important; /* Gold color */
  border-radius: 8px;
  background-color: var(--background-very-light);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.roundCorners {
  border-radius: 8px;
}

.ant-space.frame img {
  width: 100%;
  border-radius: 10px;
}

/* Markdown */
.markdown-primary {
  background-color: var(--background-accent-dark);
  color: var(--title-text-light);
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: var(--button-primary-border-color);
  font-size: var(--body-text-size-medium);
  font-family: var(--main-font);
  border-radius: 8px;
  padding: 8px;
}

.button-margined-small
{
  margin: 8px !important;
  padding: 8px !important;
}

.element-margined-right-small
{
  margin-right: 16px !important;
}

.element-margined-small{
  margin-left: 16px !important;
  padding: 8px;
}

.element-margined-medium {
  margin-left: 20px !important;
  padding: 16px;
  padding-top: 24px;
}

.element-margined-small-all{
  margin: 16px !important;
  padding: 8px;
}

.element-margined-top-small
{
  margin-top: 16px !important;
}

.element-margined-right-medium
{
  margin-right: 36px !important;
}

.element-margined-tiny{
  margin-left: 2px !important;
  padding: 2px !important;
}


.fab-element-margin-top-small{
  margin-top: 4px !important;
}

.element-margined-nill{
  margin: 0px !important;
  padding: 0px !important;
}

/* Responsive Header */
@media (max-width: 768px) {
  .site-header {
    min-height: calc(var(--header-height) * 2) !important;
    padding: 0px !important;
  }
}

@media (max-width: 576px) {
  .site-header {
    min-height: calc(var(--header-height) * 2) !important;
    padding: 0px !important;
  }
}

/* Expand Button Position Adjustment */
.expand-button {
  position: fixed;
  top: calc(var(--header-height) + 70px);
  left: 16px;
  z-index: 100;
}

@media (max-width: 768px) {
  .expand-button {
    position: fixed;
    top: calc((var(--header-height) *2) + 50px);
    left: 16px;
    z-index: 100;
  }
}


@media (max-width: 768px) {
  .main-background {
    padding-top: calc(var(--header-height)*2); /* Adjust for smaller screens */
  }
}

.custom-search .ant-input-search-button
{
  height: 32px !important;
  width: 32px !important;
  padding: 0px !important;
  margin: 0px !important;
  background-color: var(--background-accent-dark);
  color: var(--title-text-light) !important;
  border: 1px solid var(--background-accent-medium) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: var(--button-primary-border-color) !important;
  font-size: var(--body-text-size-medium);
  font-family: var(--main-font);
}

.custom-search .ant-input-search-button:hover{
  background-color: var(--title-text-light) !important;
  color: var(--background-accent-dark) !important;
  border-color: var(--button-primary-border-color) !important;
  }


.custom-search .ant-input-affix-wrapper {
  display: flex;
  align-items: stretch; /* Ensure the input field and button align properly */
}

.custom-search .ant-input {
  height: auto; /* Allow the input field to auto adjust its height */
  background-color: var(--background-light) !important;

}

.custom-search .ant-input:focus
{
  background-color: var(--background-very-light) !important;
}

.links-active {
  background-color: var(--background-medium); /* #273E3B */
  color: var(--body-text-light); /* #BFE5D3 */
  padding: 4px 16px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  margin-right: 8px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.links-active:hover {
  background-color: #2F4F4F; /* Slightly darker background on hover */
  color: #FFFFFF; /* Change text color on hover for contrast */
}

.list-item-compact {
  padding: 4px 0 !important;
  margin: 0 !important;
}

.ant-message-info
{
  margin: 0 !important;
  padding: 4px 8px !important; /* Slightly increased padding for better spacing */
  text-align: center !important;
  border-radius: 8px !important;
  background-color: var(--background-medium) !important;
  color: var(--body-text-light);
  font-size: 16px; /* Ensures readability */
}
.ant-message-notice-content {
  background-color: var(--background-medium) !important;
}

.ant-message-success
{
  margin: 0 !important;
  padding: 4px 8px !important; /* Slightly increased padding for better spacing */
  text-align: center !important;
  border-radius: 8px !important;
  background-color: var(--background-medium) !important;
  color: var(--title-text-glowmode);
  font-size: 16px; /* Ensures readability */
}

.ant-message-error
{
  margin: 0 !important;
  padding: 4px 8px !important; /* Slightly increased padding for better spacing */
  text-align: center !important;
  border-radius: 8px !important;
  background-color: var(--background-accent-dark) !important;
  color: var(--body-text-extra-light);
  font-size: 16px; /* Ensures readability */
}

